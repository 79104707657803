import consumer from "./consumer"

const card = document.getElementById("card");

if (card) {
  consumer.subscriptions.create({ channel: "CardChannel", token: card.dataset.token }, {
    received(data) {
      location.reload();
    }
  });
}
