$(document).ready(function () {
  $('a[data-target="#spil-transaction-modal"]').on('click', function () {
    const transactionId = $(this).data('transaction-id');
    $('#spil-transaction-modal').load('/transactions/' + transactionId);
  });

  $('a[data-target="#rtg-transaction-modal"]').on('click', function () {
    const transactionId = $(this).data('transaction-id');
    $('#rtg-transaction-modal').load('/rtg_transactions/' + transactionId);
  });

  $("#new_spil_transaction").on('submit', function() {
    const menu = $('.card-transactions .dropdown-menu');
    const send = $('#send-button');
    const amount = $('#amount');
    const spinner = $('.card-transactions .spinner-grow');

    menu.hide();
    send.prop('disabled', true);
    amount.prop('readonly', true);
    spinner.addClass('d-inline-block');
  });

  document.body.addEventListener('ajax:success', function(event) {
    location.reload();
  });
});
