// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");
require('packs/cards');
require("bootstrap");
require("chartkick");
require("chart.js");
require("bootstrap-daterangepicker");
const List = require("list.js/src/index");

function checkIsAutoFilled($container, data) {
  if ($container.length && data.fields.length && data.target) {
    const $items = data.fields.map(item => ($container.find(`input[name="${item}"]`)));
    const $target = $container.find(`input[name="${data.target}"]`);
    const autofilled = {};

    const isAutoFilledTotal = () => {
      const length = Object.keys(autofilled).length;
      const truly = Object.keys(autofilled).map(item => (autofilled[item]-0)).reduce((a,b) => (a+b));

      return (truly/length) >= 0.5;
    };

    const runChecker = ($item) => {
      let valLength = $item.val().length;

      $item.bind('keyup', function() {
        const newValLength = $(this).val().length;
        autofilled[$item.attr('name')] = (newValLength - valLength) > 1;
        valLength = newValLength;
      })
    };

    $items.forEach($item => {
      autofilled[$item.attr('name')] = false;

      $item.bind('focus', function() {
        if (!$(this).val()) {
          runChecker($(this));
        } else {
          if (!autofilled[$(this).attr(name)]) {
            runChecker($(this));
          }
        }
      }).bind('blur', () => {
        $target.val(isAutoFilledTotal());
      });
    })
  }
}

$(document).ready(function () {
  $('.dropdown-btn').click(function() {
    $(this).next().find('a').toggleClass('display-block');
  });

  $('#js-sign-in-form-link').on('click', function(e) {
    e.preventDefault();
    $('#js-sign-in-form-link').hide();
    $('#js-sign-in-form').slideDown();
  });

  $('.stock-product').on('ajax:success', function(){
    if ($(this).text() === 'Stock') {
      $(this).text('Unstock');
      $(this).parents('tr').addClass('text-muted');
      $(this).attr('data-confirm', 'Are you sure');
    } else {
      $(this).text('Stock');
      $(this).removeAttr('data-confirm');
      $(this).parents('tr').removeClass('text-muted');
    }
  });

  $('.unstock-product').on('ajax:success', function(){
    $(this).parents('tr').remove();
  });

  $('.toast').toast('show');
  $('.dropdown-toggle, #more-button').dropdown();
  $('[data-toggle="tooltip"]').tooltip();

  checkIsAutoFilled($('#order_form'), {
    fields: ['order[identifier_confirmation]', 'order[identifier]'],
    target: 'order[autofilled]'
  });

  var options = {
    valueNames: [ 'product-name', 'product-short-description' ]
  };

  var productList = new List('products', options);

  const datesSelector = $('input[name="dates"]');
  const periodSelectors = $('input[name="fixed_product[banner_attributes][period]"], input[name="open_product[banner_attributes][period]"]');
  const endDateSelectors = $('input[name="fixed_product[end_date]"], input[name="open_product[end_date]"]');
  const merchantSelector = $('#merchant_id');

  function updateReportDownloadLink(selector, urlParam) {
    const reportDownloadSelector = $('#report-download');
    const href = new URL(reportDownloadSelector.attr('href'));

    href.searchParams.set(urlParam, selector.val());
    reportDownloadSelector.attr('href', href.toString());
  }

  datesSelector.daterangepicker();
  datesSelector.on('change', function() {
    updateReportDownloadLink(datesSelector, 'dates')
  });

  endDateSelectors.daterangepicker({ singleDatePicker: true, autoUpdateInput: false });
  endDateSelectors.on('apply.daterangepicker', function(e, picker) {
    $(this).val(picker.startDate.format('YYYY-MM-DD'));
  });

  periodSelectors.daterangepicker({ autoUpdateInput: false });
  periodSelectors.on('apply.daterangepicker', function(e, picker) {
    $(this).val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));
  });

  merchantSelector.on('change', function() {
    updateReportDownloadLink(merchantSelector, 'merchant_id')
  });

  $('.category-link').click(function(){
    const divId = $(this).attr('href');

    $('html').animate({
      scrollTop: $(divId).offset().top - 103
    }, 200);
  });

  addEventListener('click', function(event) {
    var el = event.target;

    if (el.tagName === "A" && !el.isContentEditable && el.host !== window.location.host) {
      el.setAttribute("target", "_blank");
    }
  }, true);
});



window.remove_fields = function(link) {
  $(link).prev('input[type=hidden]').val('1');
  $(link).closest(".fixed-denom").hide();
};

window.add_fields = function(link, association, content) {
  const newId = new Date().getTime();
  const regexp = new RegExp(`new_${association}`, 'g');
  $(`.${association}-list`).append(content.replace(regexp, newId));

  $('.remove-denom-link').last().append(feather.toSvg('trash-2'));
};
